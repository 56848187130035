body {
    font-family: "Source Sans Pro";
}

@font-face {
    font-family: "Source Sans Pro"; /* can be any text */
    src: url("./fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

h1 {
    font-size: 4rem;
    margin-top: 0;
    margin-bottom: 0;
}

h2 {
    margin-top: 0;
    margin-bottom: 0;
}

h3 {
    margin-top: 0;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Ensures content that overflows the container is hidden */
    text-overflow: ellipsis; /* Displays an ellipsis (...) for clipped text */
}

.gradient-mask-container img {
    -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0.9),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
    );
    mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0.9),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
    );
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}